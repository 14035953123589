import React from 'react';
import '../styles/templates/news.css';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

// Component
import Layout from '../components/layout';

const ProductIntro = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { title },
        html,
      },
    },
  } = props;
  return (
    <Layout>
      <Container>
        <h1>{title}</h1>
        {/* eslint-disable */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
        {/* eslint-disable */}
      </Container>
    </Layout>
  );
};

export const productIntroQuery = graphql`
  query ProductIntroQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        locale
        category
      }
      html
    }
  }
`;

export default ProductIntro;
